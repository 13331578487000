<template>
  <div>
    <!-- <h3 class="font-weight-bold mb-2 ml-1">Permissions</h3> -->
    <b-row class="mr-0">
      <b-col cols="12" class="mb-2">
        
        <h3 class="ml-2">{{role.name + ' Role'}}</h3>
      </b-col>
      <!-- <b-col
        md="4"
        v-for="(permissionGroup,groupName,index) in permissions" :key="index"
      >
        <b-card class="mx-1 w-100 rounded-lg">
          <h5>{{groupName}}</h5>
          <b-form-checkbox
            v-for="(permission, index) in permissionGroup" :key="permission.id"
            v-model="selected[index]"
            :value="permission.name"
            class="custom-control-success"
          >
            {{permission.name}}
          </b-form-checkbox>
        </b-card>
      </b-col> -->
      <b-col
        md="4"
        class="pb-2"
        v-for="(block,index) in blocks" :key="index"
      >
        <b-card class="mx-1 w-100 rounded-lg h-100" >
          <h5>{{block}}</h5>
          <b-form-checkbox
            v-for="(permission) in filteredPermissions(block)" :key="permission.id"
            v-model="selected[permission.id]"
            :value="permission.name"
          >
            {{permission.name}}
          </b-form-checkbox>
        </b-card>
      </b-col>
    </b-row>
    <b-button
      variant="primary"
      class="rounded-pill my-2 ml-1"
      type="submit"
      @click="submit()"
    >
      Save
    </b-button>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BCardText,
  BButton,
  BLink,
  BListGroup,
  BListGroupItem,
  BCardTitle,
  BCardBody,
  BFormCheckbox,
  BFormInput,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapGetters } from "vuex";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BCardText,
    BButton,
    BLink,
    BListGroup,
    BListGroupItem,
    BCardTitle,
    BCardBody,
    BFormCheckbox,
    BFormInput
  },
  directives: {
    Ripple,
  },
  computed: {
    ...mapGetters({
      permissions: "users/permissions",
      assignedPermissions: "users/assigned_permissions",
      role: "users/role"
    }),
    id(){
      return this.$route.params.id ? this.$route.params.id : null
    }
  },
  data() {
    return {
      selected:[],
      blocks: [],
    }
  },
  methods:{
    init(){
      this.$store.dispatch('users/listPermissions', this.id).then(_=>{

        this.selected = this.assignedPermissions
        for (let index = 0; index < this.permissions.length; index++) {
          let str = this.permissions[index].name.split(" ")
          this.blocks.push(str[str.length - 1]);
        }
        this.blocks = this.blocks.filter(
          (value,index) => this.blocks.indexOf(value) === index
        )
      })
    },
    filteredPermissions(block) {
        return this.permissions.filter(item => item.name.endsWith(block))
    },
    submit(){
        this.$store.commit('app/UPDATE_RERENDER', false)
        const payload = { id: this.id , query:{ name: this.role.name, permissions: this.selected } }
        this.$store.dispatch( 'users/addRole' , payload ).then(res=>{
          // this.$router.push({name:'roles'})
          this.$store.dispatch('users/userPermissions', this.authUser().user.id).then(_=>{
            this.$store.commit('app/UPDATE_RERENDER', true)
          });
          this.$swal({
              icon: 'success',
              title: `<h4>Role Updated Successfully</h4>`,
              showConfirmButton: true,
              confirmButtonColor: '#E84185',
              allowOutsideClick: true
          });
        }).catch(error => {
          if (error.response) {
            this.$store.commit('app/UPDATE_RERENDER', true)
            this.$swal({
              icon: 'error',
              title: `<h4>${error.response.data.error}</h4>`,
              showConfirmButton: true,
              confirmButtonColor: '#E84185',
              allowOutsideClick: true
            });
          }
      }).finally(_=>{
        this.$store.commit('app/UPDATE_RERENDER', true)
      });
    }
  },
  mounted(){
    this.init()
  }
}
</script>

<style lang="scss">
  @import '@core/scss/vue/pages/dashboard-ecommerce.scss';
  @import '@core/scss/vue/libs/chart-apex.scss';
  .custom-control-label {
    cursor: pointer !important;
  }

</style>
<style scoped>
  tr{
    cursor: unset !important;
  }
</style>
